::-webkit-input-placeholder {
  color: #1c315f;
}
::-moz-placeholder {
  color: #1c315f;
} /* Firefox 19+ */
:-moz-placeholder {
  color: #1c315f;
} /* Firefox 18- */
:-ms-input-placeholder {
  color: #1c315f;
}
