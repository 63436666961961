.container {
  display: flex;
  flex-wrap: wrap;
}

.container > div {
  margin: 0.5% !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header .full-name {
  font-size: 19px;
  margin-left: 5px;
  color: #1a2c62;
  text-shadow: 2px 2px rgb(0 0 0 / 18%);
}

.comp-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.matchup-score .score {
  display: flex;
  justify-content: space-around;
  align-content: center;
}

.search-comp-item {
  width: 30%;
}

.matchup-comp-item {
  width: 15%;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.event-comp-item,
.search-comp-item,
.search-comp-btns,
.event-comp-select {
  margin: 5px 0;
}

.source-data-btns {
  width: 13%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.search-comp-btns button {
  margin: 15px 20px 0;
}

.matchup-score {
  width: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.event-comp-select {
  display: flex;
  flex-direction: column;
}

.inputik {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-size: 14px;
  box-sizing: border-box;
  align-items: center;
  font-family: Roboto, sansSerif;
  font-weight: 500;
  line-height: 1.1876em;
  color: black;
  outline: none;
  padding: 6px 0 7px;
}
