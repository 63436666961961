.blurring {
  filter: blur(6px);
  cursor: default !important;
}

.blurring a:hover {
  cursor: default !important;
  text-decoration: none;
}

.MuiButton-root {
  border-radius: 3px !important;
  font-family: 'Roboto' !important;
  padding: 8px 20px;
  text-decoration: 'none' !important;
}

.MuiCard-root {
  overflow: visible !important;
}

.MuiDialog-paper {
  overflow: visible !important;
}
